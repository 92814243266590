.cookies-conf {
  position: fixed;
  z-index: 999;
  bottom: 0;
  padding: 0 20px;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  a {
    color: #f55b23;
  }
  &-main {
    margin: 0 auto;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0;
  }
  &-text {
    height: 100%;
  }
  &-btn {
    flex-shrink: 0;
    flex-direction: row;
    height: 100%;
  }
  &-btn button {
    cursor: pointer;
    @height: 38px;
    @w: 140px;
    display: inline-block;
    height: @height;
    width: @w;
    line-height: @height - 2px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1.25px;
    border-radius: @height / 2;
    box-shadow: 0 4px 8px 0 rgba(80, 39, 0, 0.65);
    vertical-align: middle;
    color: #fff;
    border: none;
    background-color: #f55b23;
    margin: 12px 0 12px 20px;
    &:active,
    &:hover {
      color: #f55b23;
      background-color: #fff;
    }
  }
  &-btn button.disAllowed {
    background-color: inherit;
    border: #fff solid 1px;
    font-weight: 400;
    opacity: 0.45;
    &:active,
    &:hover {
      background-color: #f2f2f2;
      color: #000;
    }
  }
}

@media (max-width: 720px) {
  .cookies-conf {
    &-main {
      flex-direction: column;
      padding: 12px 0;
    }
    &-text {
      max-height: 15vh;
      overflow-y: auto;
    }
    &-btn {
      button {
        @h: 32px;
        font-size: 14px;
        width: 120px;
        height: @h;
        line-height: @h - 2px;
        margin: 12px 12px 4px;
      }
    }
  }
}
