@font-face {
  font-family: 'Lato';
  font-display: auto;
  font-style: normal;
  src: url(../../fonts/lato/lato_light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Lato';
  font-display: auto;
  font-style: italic;
  src: url(../../fonts/lato/lato_lightitalic.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Lato';
  font-display: auto;
  font-style: normal;
  src: url(../../fonts/lato/lato_regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Lato';
  font-display: auto;
  font-style: normal;
  src: url(../../fonts/lato/lato_bold.ttf);
  font-weight: 700;
}

.indexPage,
.main-footer,
.main-header {
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  .box {
    padding: 0;
    width: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 720px) {
  .main-footer,
  .main-header {
    .box {
      padding: 0 10px;
      width: 100%;
    }
  }
}
@media (min-width: 1160px) {
  .indexPage,
  .main-footer,
  .main-header {
    .box {
      padding: 0;
      max-width: 1140px;
      margin: 0 auto;
    }
  }
}

.indexMain {
  .xs-hide {
    display: none;
  }
  font-family: 'Lato', 'Hind Siliguri', 'open sans', Helvetica, 'Helvetica Neue',
    sans-serif, 'Microsoft Yahei';
  font-weight: 300;
  letter-spacing: 0;
  color: #000000;

  a,
  input {
    color: inherit;
    -webkit-appearance: none;
    outline: none;
    border-radius: 0;

    &:active,
    &:focus,
    &:hover {
      outline: none;
    }
  }

  .title-edge {
    position: relative;

    &::after,
    &::before {
      @h: 2px;
      content: '';
      position: absolute;
      width: 0.4rem;
      height: @h;
      background-color: #000000;
      top: 50%;
      margin-top: -@h / 2;
    }

    &::after {
      right: -0.7rem;
    }

    &::before {
      left: -0.7rem;
    }
  }

  .main-content {
    width: 100%;
    margin: 0;
  }

  &-banner {
    position: relative;
    color: #fff;

    .main-content {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }

    .topbanner {
      height: 100%;
      position: relative;
      overflow: hidden;

      &-img {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
      }

      &::after {
        position: absolute;
        z-index: 0;
        content: '';
        background-color: rgba(0, 0, 0, 0.2);
        /* shade_mob_podcaster: */
        background-image: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.63) 0%,
          rgba(0, 0, 0, 0.02) 47%,
          rgba(245, 91, 35, 0) 49%,
          rgba(245, 91, 35, 0.64) 100%
        );
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
}
@media (min-width: 1024px) {
  .indexMain {
    .xs-hide {
      display: block;
    }

    .title-edge {
      &::after,
      &::before {
        width: 40px;
      }

      &::after {
        right: -70px;
      }

      &::before {
        left: -70px;
      }
    }

    .main-content {
      width: 1024px;
      margin: 0 auto;
    }

    &-banner {
      position: relative;

      .topbanner {
        &-img {
          width: 100%;
          height: auto;

          &::after {
            background-color: rgba(0, 0, 0, 0);
            background-image: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.61) 0%,
              rgba(0, 0, 0, 0.02) 53%,
              rgba(245, 91, 35, 0) 54%,
              rgba(245, 91, 35, 0.5) 100%
            );
          }
        }
      }
    }
  }
}
